<template>
<div class="RechargePic">
  <van-nav-bar
    :title="title"
    left-arrow
    @click-left="onClickLeft"
  />
  <div class="content">
    <div class="recharge_info" v-if="type=='YHK'">
      <div v-show="money >= 500">
        <van-cell :border="false" @click="yhkCopy">
          <template #title>
            银行卡号
          </template>
          <template #default>
            6225883110366429
          </template>
          <template #right-icon>
            <van-tag plain>复制</van-tag>
          </template>
        </van-cell>
        <van-cell :border="false">
          <template #title>
            持卡人姓名
          </template>
          <template #default>
            宋雅玮
          </template>
        </van-cell>
        <van-cell :border="false">
          <template #title>
            开户行
          </template>
          <template #default>
            招商银行（石家庄裕华支行）
          </template>
        </van-cell>
      </div>
      <div v-show="money < 500" style="color: red; font-size: 14px;text-align: center; line-height: 40px;">
        <div>输入充值金额显示收款账户</div>
        <div style="font-weight: bold;">充值金额必须大于500元</div>
      </div>
    </div>
    <div class="recharge_info" v-if="type=='ZFB'">
      <van-cell :border="false" @click="zfbCopy" v-if="false">
        <template #title>
          支付宝账号
        </template>
        <template #default>
          qffice@163.com
        </template>
        <template #right-icon>
          <van-tag plain>复制</van-tag>
        </template>
      </van-cell>
      <van-cell :border="false" style="padding-bottom: 0;" title-class="title_class_center">
        <template #title>
          支付宝收款码
        </template>
      </van-cell>
      <div v-show="money >= 500">
        <van-cell :border="false" style="padding-top: 0;" title-class="title_class_center">
          <template #title>
            <van-image
              width="200px"
              height="200px"
              lazy-load
              src="https://img.midbest.cn/uploads/image/chongzhi/2020/10/27/41bfcd6685940ef58cf177c21a5c5001.png"
              @click="yulan"
            />
          </template>
        </van-cell>
      </div>
      <div v-show="money < 500" style="height: 200px; color: red; font-size: 14px;text-align: center; line-height: 40px;overflow:hidden;">
        <div style="margin-top: 20px;">输入充值金额显示收款二维码</div>
        <div style="font-weight: bold;">充值金额必须大于500元</div>
      </div>
      <van-cell :border="false">
        <template #title>
          收款人姓名
        </template>
        <template #default>
          宋雅玮
        </template>
      </van-cell>
    </div>
    <div class="recharge_button">
      <van-form @submit="onSubmit">
        <van-field
          :border="false"
          v-model="money"
          name="money"
          label="充值金额"
          placeholder="请输入充值金额"
          error-message="最低充值金额500元"
          :rules="[{ required: true}]"
          @input="MoneyChange"
        />
        <van-cell class="active" :border="false" v-if="activeList && activeList.length > 0">
          <template #icon>
            <div style="width: 102px;text-align: left;">活动列表</div>
          </template>
          <template #default>
            <div style="font-size: 11px; color: #EE0A24; line-height: 20px;">注意：充值活动赠品请在{{activeList[0].stop_time_str}}零点之前领取，如未领取视为自动放弃，不予售后，请知晓！！</div>
            <van-radio-group v-model="activid" direction="horizontal">
              <van-radio
               v-for="(item, index) in activeList" 
               :key="index" 
               :name="item.id" 
               checked-color="#ee0a24" 
               @click="showInfo(item)"
               icon-size="14px" 
               :disabled="(index < activeList.length -1 && money >= item.money && money < activeList[index+1].money) || (money >= activeList[activeList.length -1].money && index == activeList.length -1) ? false : true"
              >{{item.title}}</van-radio>
            </van-radio-group>
          </template>
        </van-cell>
        <van-field name="uploader" label="上传转账凭证" center :border="false">
          <template #input>
            <van-uploader
              v-model="uploader"
              :max-count="1"
              :after-read="afterRead"
              :before-read="beforeRead"
              :before-delete="beforeDelete"
            />
          </template>
        </van-field>
        <div style="margin: 16px;">
          <van-button
            round
            block
            type="danger"
            :loading="!czload"
            :native-type="czload?'submit':'button'"
          >
            提交
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
  <!-- 赠送礼品 -->
  <van-dialog
    :title="zengpinInfo.title"
    v-model="zengpinShow"
  >
    <div class="dialog_roup">
      <van-cell-group :title="'以下产品：' + zengpinInfo.type_str" :border="false">
        <van-cell v-for="(item, index) in zengpinInfo.goods" :key="index">
          <div slot="icon">
            <van-image
             :src="item.goodsthumb"
             width="60"
             height="60"
             style="margin-right: 10px;"
             fit="cover"
            >
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
          </div>
          <template slot="title">
            <div class="van-multi-ellipsis--l2" style="font-size: 12px; line-height: 16px;">{{item.goodstitle}}</div>
            <div style="font-size: 10px; color: #999; line-height: 16px;">{{item.title}}</div>
            <div style="font-size: 10px; color: #999; line-height: 16px;">{{'赠送数量：'+item.num}}</div>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
  </van-dialog>
</div>
</template>
<script>
import { ImagePreview } from 'vant';
export default {
  name: "RechargePic",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      shareid: 0,
      merchid: 0,
      title: "",
      type: "",
      uploader: [], // 文件上传预览
      money: "",
      chongzhiPic: "",
      czload: true,
      activeList: [], // 活动列表
      activid: 0, // 活动id
      zengpinShow: false, // 是否显示赠品列表
      zengpinInfo: {},
    }
  },
  mounted() {
    this.init();
    this.type = this.$route.query.type;
    if (this.type == "ZFB") {
      this.title = "支付宝转账";
    }else if (this.type == "YHK") {
      this.title = "银行卡转账";
    }
  },
  methods: {
    init() {
      console.log("sss");
      this.getRechargeActive(); // 获取活动列表
    },
    getRechargeActive () {
      let _that = null;
      _that = this;
      const toast = _that.$toast({
        type: 'loading',
        message: '加载中...',
        forbidClick: true,
        duration: 0
      })
      _that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
      _that.$axios.defaults.headers["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(_that.$store.state.domain + "web/recharge/ActiveList")
        .then(res => {
          toast.clear();
          console.log(res, "活动列表");
          if (res.data.code == 100000) {
            _that.activeList = res.data.data;
          }
        })
        .catch(err => {
          console.log(err);
          toast.clear();
        })
    },

    // 获取输入金额
    MoneyChange(val) {
      console.log(val);
      this.money = parseFloat(val);
      this.activid = 0;
      if (this.money > 0) {
        this.activeList.forEach((item, index) => {
        if (this.money >= parseFloat(item.money)) {
          if (index == this.activeList.length-1) {
            this.activid = item.id;
          }else {
            if (this.money <= parseFloat(this.activeList[index+1].money)) {
              this.activid = item.id;
            }
          }
        }
      })
      }else {
        this.money = "";
      }
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back();
    },
    // 提交按钮
    onSubmit() {
      console.log("submit");
      let _that = null;
      _that = this;
      if (_that.money && _that.money > 0) {
        _that.money = parseFloat(_that.money);
      }else {
        _that.$toast("请输入充值金额");
        return false;
      }
      if (!_that.chongzhiPic || _that.chongzhiPic.length < 1) {
        _that.$toast("请上传转账凭证");
        return false;
      }

      if (_that.czload) {
        _that.czload = false;
        _that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
        _that.$axios.defaults.headers["Authorization"] = localStorage.getItem("DK_UID");
        _that.$axios
          .post(
            _that.$store.state.domain + "web/user/recharge",
            _that.$qs.stringify({
              money: _that.money,
              pic: _that.chongzhiPic,
              type: _that.type,
              activitid: _that.activid,
            })
          )
          .then(res => {
            console.log(res);
            if (res.data.code == 100000) {
              _that.$toast(res.data.msg ? res.data.msg : "提交成功，等待审核");
              _that.onClickLeft();
            }else {
              _that.$toast(res.data.msg ? res.data.msg : "提交失败");
            }
          })
          .catch(err => {
            console.log(err);
          })
      }
    },
    // 复制支付宝账号
    zfbCopy() {
      let _that = null;
      _that = this;
      _that.$copyText("qffice@163.com").then(
        function(e) {
          _that.$toast("复制成功");
          console.log(e);
        },
        function(e) {
          console.log(e);
        }
      );
    },
    // 复制支付宝账号
    yhkCopy() {
      let _that = null;
      _that = this;
      _that.$copyText("6225883110366429").then(
        function(e) {
          _that.$toast("复制成功");
          console.log(e);
        },
        function(e) {
          console.log(e);
        }
      )
    },
    // 上传前判断只有返回true 才能上传
    beforeRead() {
      return true;
    },
    // 上传
    afterRead(file, detail) {
      let _that = null;
      _that = this;

      const fordata = new FormData();
      fordata.append('file',file.file);
      fordata.append('dir','image/chongzhi/');
      fordata.append('merchid',_that.merchid);
      _that.$axios.defaults.headers["Content-Type"] =  "multipart/form-data";
      _that.$axios.post(
        "https://api.midbest.cn/admin/image/shangchuan",
        fordata
      ).then( response =>{
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        if (response.data && response.data.code == 100000) {
          _that.chongzhiPic = response.data.img_url;
        }else {
          _that.uploader = [];
          _that.chongzhiPic = "";
          _that.$toast(response.data.msg ? response.data.msg : "上传失败");
        }
      }).catch( error =>{
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        console.log(error);
      })
    },
    // 删除截图
    beforeDelete(file, detail) {
      this.uploader = [];
      this.chongzhiPic = "";
    },
    yulan () {
      ImagePreview(['https://img.midbest.cn/uploads/image/chongzhi/2020/10/27/41bfcd6685940ef58cf177c21a5c5001.png']);
    },
    showInfo(recharge) {
      this.zengpinInfo = recharge;
      this.zengpinShow = false;
      if(this.zengpinInfo.lipinType == '1'){
        this.zengpinShow = true;
      }
      console.log(this.zengpinInfo, this.zengpinShow);
    },
  }
}
</script>
<style lang="less">
.RechargePic {
  background-color: #fff;
  width: 100%;
  min-height: 100%;
  .van-nav-bar .van-icon {
    color: #333;
  }
  // 转账信息
  .recharge_info {
    .van-cell {
      .van-cell__title {
        width: 90px;
        text-align: left;
        margin-right: 12px;
        color: #999;
      }
      .van-cell__value {
        flex: 1;
        text-align: left;
        color: #333;
      }
      .title_class_center {
        text-align: center;
        flex: 1;
        margin-right: 0;
        .van-image {
          display: block;
          margin: 0 auto;
        }
      }
    }
  }
  // 上传样式
  .recharge_button {
    padding-bottom: 60px;
    .van-form {
      .van-field {
        .van-field__label {
          align-items:center;
          width: 90px;
          color: #999;
        }
        .van-field__value {
          flex: 1;
          justify-content: flex-end;
          .van-field__control {
            justify-content: flex-end;
          }
        }
      }
      .active {
        .van-cell__value {
          flex: 1;
          .van-radio {
            line-height: 40px;
            height: 40px;
          }
        }
      }
    }
  }
  .dialog_roup {
    text-align: left;
    overflow: hidden;
    max-height: 200px;
    overflow-y: auto;
    .van-cell {
      overflow: hidden;
      .van-cell__title {
        flex: 1;
        overflow: hidden;
      }
    }
  }
}
</style>